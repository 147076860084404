<template>
  <b-card>

    <b-row>

      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="userData.avatar && server + '/' + userData.avatar"
            :text="avatarText(fullName(userData))"
            :variant="`light-${resolveUserRoleVariant(userData.role)}`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ fullName(userData) }}
              </h4>
              <span class="card-text">{{ userData.email }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{ name: 'user-edit', params: { id: userData.id } }"
                variant="primary"
              >
                {{ $t('Edit') }}
              </b-button>
              <!-- <b-button
                v-if="userData.status === 10"
                variant="outline-danger"
                class="ml-1"
                @click="blockUser(userData)"
              >
                {{ $t('Block') }}
              </b-button>
              <b-button
                v-if="userData.status === 0"
                variant="outline-success"
                class="ml-1"
                @click="unBlockUser(userData)"
              >
                {{ $t('Unblock') }}
              </b-button> -->
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('Username') }}</span>
            </th>
            <td class="pb-50">
              {{ userData.login }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="MailIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('Email') }}</span>
            </th>
            <td class="pb-50">
              {{ userData.email }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('Phone') }}</span>
            </th>
            <td class="pb-50">
              {{ userData.phone }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('Status') }}</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ resolveUserStatusText(userData.status) }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('Role') }}</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ resolveUserRoleText(userData.role) }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckSquareIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Тариф</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.tariff.name }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import useUsersList from '../users-list/useUsersList'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const {
      resolveUserRoleVariant,
      resolveUserRoleText,
      resolveUserStatusVariant,
      resolveUserStatusText,
      fullName,
      blockUser,
      unBlockUser,
    } = useUsersList()

    const server = process.env.VUE_APP_SERVER

    return {
      avatarText,
      resolveUserRoleVariant,
      resolveUserRoleText,
      resolveUserStatusVariant,
      resolveUserStatusText,
      fullName,
      blockUser,
      unBlockUser,

      server,
    }
  },
}
</script>

<style>

</style>
