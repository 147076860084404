<template>
  <div>

    <b-overlay
      :show="$store.state.app.loading"
      no-wrap
      :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
    />

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        {{ $t('message.Error fetching user data') }}
      </h4>
      <div class="alert-body">
        {{ $t('message.No user found with this user id') }}
        <b-link
          class="alert-link"
          :to="{ name: 'users'}"
        >
          {{ $t('message.Check User List for other users') }}
        </b-link>
      </div>
    </b-alert>

    <div v-if="userData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="12"
          lg="12"
          md="12"
        >
          <user-view-user-info-card :user-data="userData" />
        </b-col>
      </b-row>
    </div>

  </div>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink, BOverlay,
} from 'bootstrap-vue'
import store from '@/store'
import router from '@/router'
import usersStoreModule from '../usersStoreModule'
import UserViewUserInfoCard from './UserViewUserInfoCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BOverlay,

    // Local Components
    UserViewUserInfoCard,
  },
  setup() {
    const userData = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'app-users'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, usersStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-users/fetchUser', { id: router.currentRoute.params.id })
      .then(response => {
        const { data } = response
        userData.value = data

        const fio = userData.value.fio.split(' ')
        if (fio && fio.length > 0) {
          if (fio[0]) {
            // eslint-disable-next-line prefer-destructuring
            userData.value.last_name = fio[0]
          }
          if (fio[1]) {
            // eslint-disable-next-line prefer-destructuring
            userData.value.first_name = fio[1]
          }
          if (fio[2]) {
            // eslint-disable-next-line prefer-destructuring
            userData.value.surname = fio[2]
          }
        }
      })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = undefined
        }
      })

    return {
      userData,
    }
  },
}
</script>

<style>

</style>
